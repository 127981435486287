import React from 'react';

const AlreadyAppliedIllustration = () => (
	<svg
		width="296"
		height="220"
		viewBox="0 0 296 220"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M118.146 213.456C118.146 213.456 162.653 224.253 194.642 195.244C226.631 166.234 232.054 140.017 237.543 99.0959C243.032 58.1753 221.546 19.65 186.264 5.03077C150.983 -9.58848 138.488 19.3792 96.8697 25.4941C55.2508 31.609 42.0279 26.421 14.7816 56.1289C-12.4648 85.8369 -0.866877 122.569 39.8853 164.326C80.6374 206.083 118.146 213.456 118.146 213.456Z"
			fill="#DCF3F2"
		/>
		<mask
			id="mask0_1496_7922"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="239"
			height="220"
		>
			<path
				d="M118.146 213.455C118.146 213.455 140.256 220.841 164.857 213.455C167.033 212.802 170.298 219.134 182.812 219.134C193.693 217.502 213.815 222.58 219.265 214.237C219.265 213.726 233.028 174.824 234.449 172.152C235.926 169.377 236.058 154.54 237.264 151.681C238.43 148.916 228.072 146.051 228.242 145.539C232.786 131.877 235.158 116.874 237.543 99.0954C243.032 58.1748 221.546 19.6495 186.264 5.03024C159.832 -5.92251 147.277 6.69703 124.595 16.7354C122.67 17.5875 120.679 9.42143 118.61 10.2064C115.214 11.4948 106.71 15.2054 102.832 16.1913C101.246 16.5944 102.832 24.4344 101.743 24.6512C101.321 24.7353 100.895 24.8172 100.467 24.8966C99.2919 25.1142 98.0934 25.3138 96.8697 25.4936C87.1511 26.9215 78.9808 19.7604 71.8189 20.544C48.3106 23.1157 35.6655 33.3577 14.7816 56.1284C-6.55017 79.3874 -4.07173 106.952 17.5666 137.862C20.7413 142.397 26.4838 149.712 30.4686 154.388C35.9095 160.773 45.1589 169.623 52.2319 176.152C57.8434 181.331 59.8491 182.681 65.834 187.033C71.0708 190.842 75.6275 194.106 82.1565 197.915C86.5091 201.063 93.4516 204.223 95.4987 205.163C111.108 212.328 118.146 213.455 118.146 213.455Z"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0_1496_7922)" />
		<g clipPath="url(#clip0_1496_7922)">
			<path
				d="M113.807 164.093C113.795 177.53 140.446 173.397 173.072 173.425C205.698 173.453 231.945 177.632 231.957 164.195C231.968 150.758 205.328 147.813 172.702 147.785C140.075 147.757 113.819 150.656 113.807 164.093Z"
				fill="#50C4BC"
			/>
			<path d="M49 173.5H262V174.5H49V173.5Z" fill="black" />
			<path
				d="M136.672 145.947H140.938L142.968 129.494H136.672V145.947Z"
				fill="#E6C8BC"
			/>
			<path
				d="M144.458 144.729H135.758V149.908H149.164V149.434C149.164 148.186 148.668 146.989 147.785 146.107C146.903 145.224 145.706 144.729 144.458 144.729Z"
				fill="black"
			/>
			<path
				d="M105.32 170.23H109.585L111.615 153.777H105.32V170.23Z"
				fill="#E6C8BC"
			/>
			<path
				d="M113.106 169.011H104.406V174.19H117.812V173.716C117.812 172.468 117.316 171.271 116.433 170.389C115.551 169.507 114.354 169.011 113.106 169.011Z"
				fill="black"
			/>
			<path
				d="M85.6453 121.353C85.3503 120.995 85.1374 120.577 85.0215 120.129C84.9056 119.68 84.8895 119.211 84.9744 118.755C85.0592 118.3 85.243 117.868 85.5127 117.491C85.7824 117.114 86.1315 116.801 86.5354 116.573L96.4421 76.2169L102.776 78.9422L90.993 117.963C91.3303 118.667 91.3976 119.47 91.1823 120.22C90.967 120.97 90.484 121.616 89.8248 122.033C89.1656 122.451 88.3761 122.613 87.6058 122.487C86.8354 122.362 86.1379 121.958 85.6453 121.353Z"
				fill="#E6C8BC"
			/>
			<path
				d="M144.554 75.6856C144.609 76.1941 144.551 76.7084 144.386 77.1924C144.22 77.6764 143.951 78.1181 143.596 78.4866C143.241 78.8551 142.81 79.1414 142.333 79.3252C141.856 79.509 141.344 79.586 140.834 79.5506L129.606 90.1414L126.587 84.5465L137.569 75.5649C137.685 74.7117 138.11 73.9307 138.764 73.37C139.417 72.8093 140.254 72.5078 141.115 72.5227C141.976 72.5376 142.802 72.8678 143.435 73.4507C144.069 74.0336 144.467 74.8288 144.554 75.6856Z"
				fill="#E6C8BC"
			/>
			<path
				d="M118.641 117.168L111.95 167.385L103.622 165.671C103.622 165.671 104.847 129.099 100.683 124.69C96.5186 120.281 106.038 112.557 106.038 112.557L118.641 117.168Z"
				fill="#424242"
			/>
			<path
				d="M115.874 103.336L118.948 107.946C118.948 107.946 142.616 98.1106 142.616 113.787C142.616 129.462 143.97 140.101 144.46 140.836C144.95 141.57 138.793 143.477 135.854 142.987C135.854 142.987 132.228 117.715 132.473 116.246C132.473 116.246 109.255 127.629 104.847 126.649C100.438 125.669 99.3961 122.201 98.6613 120.241C97.9265 118.282 98.9687 102.721 98.9687 102.721L113.664 98.2363L115.874 103.336Z"
				fill="#616161"
			/>
			<path
				d="M102.898 69.4664L103.138 70.8139C103.138 70.8139 113.697 92.5448 120.737 93.4362C127.777 94.3275 139.287 82.0291 139.357 81.4013C139.428 80.7735 134.801 79.2035 133.983 76.9106C133.983 76.9106 123.426 85.0545 122.593 85.4986C121.761 85.9427 116.773 71.7641 116.773 71.7641L111.21 68.1513L102.898 69.4664Z"
				fill="url(#paint0_linear_1496_7922)"
			/>
			<path
				d="M98.2872 64.7894C98.2872 64.7894 107.399 65.8408 107.749 65.8408C108.1 65.8408 111.209 68.1513 111.209 68.1513L116.335 74.602C116.335 74.602 120.306 106.628 119.255 106.102C118.204 105.576 98.8129 108.595 98.6377 107.194C98.4624 105.792 98.8129 98.7828 98.2872 98.4324C97.7615 98.0819 89.1756 76.3542 89.1756 76.3542C89.1756 76.3542 90.0517 71.7984 93.0305 70.2214C96.0093 68.6443 98.2872 64.7894 98.2872 64.7894Z"
				fill="url(#paint1_linear_1496_7922)"
			/>
			<path
				d="M91.9791 73.3752L89.1756 76.354C89.1756 76.354 85.01 110.583 87.2879 111.635C89.5658 112.686 94.0502 112.557 94.0502 112.557L98.4624 96.3295L91.9791 73.3752Z"
				fill="url(#paint2_linear_1496_7922)"
			/>
			<path
				d="M107.043 62.6218C102.589 62.6218 98.9781 59.0109 98.9781 54.5566C98.9781 50.1023 102.589 46.4915 107.043 46.4915C111.497 46.4915 115.108 50.1023 115.108 54.5566C115.108 59.0109 111.497 62.6218 107.043 62.6218Z"
				fill="#E6C8BC"
			/>
			<path
				d="M108.936 55.8153C109.045 54.1418 111.156 54.0845 112.833 54.0686C114.51 54.0528 116.515 54.1126 117.464 52.7299C118.091 51.816 117.971 50.5474 117.453 49.5676C116.935 48.5879 116.083 47.8344 115.244 47.1094C113.08 45.2379 110.849 43.3812 108.251 42.1833C105.653 40.9853 102.607 40.5013 99.9126 41.4629C96.6021 42.6442 92.127 48.7249 91.7405 52.2185C91.3541 55.7121 92.7503 59.2736 95.1038 61.8842C97.4573 64.4948 102.844 63.4415 106.234 64.3702C104.173 61.4534 105.532 56.1533 108.833 54.7912L108.936 55.8153Z"
				fill="#212121"
			/>
			<path
				d="M180.816 160.139C170.123 160.139 161.455 151.471 161.455 140.778C161.455 130.085 170.123 121.416 180.816 121.416C191.509 121.416 200.177 130.085 200.177 140.778C200.177 151.471 191.509 160.139 180.816 160.139Z"
				fill="#662FB7"
			/>
			<path
				d="M193.746 120.041C196.429 120.041 199.055 119.263 201.305 117.802C203.556 116.341 205.335 114.258 206.427 111.807L237.149 42.6196C238.167 40.3277 238.705 37.852 238.731 35.3445C238.757 32.8369 238.269 30.3507 237.299 28.0384C236.328 25.7262 234.895 23.6369 233.087 21.8989C231.28 20.161 229.136 18.811 226.787 17.9322C224.439 17.0533 221.935 16.664 219.43 16.7882C216.926 16.9124 214.473 17.5476 212.223 18.6546C209.973 19.7616 207.973 21.3171 206.346 23.2254C204.72 25.1338 203.5 27.3546 202.763 29.7515L180.516 102.111C179.879 104.183 179.737 106.374 180.101 108.51C180.465 110.647 181.324 112.668 182.611 114.411C183.897 116.155 185.575 117.572 187.509 118.55C189.443 119.528 191.579 120.038 193.746 120.041H193.746Z"
				fill="#662FB7"
			/>
		</g>
		<path
			d="M223.417 128.5C223.417 128.5 217.319 132.576 215.537 139.159C213.757 145.741 216.969 152.335 216.969 152.335C216.969 152.335 223.068 148.259 224.849 141.676C226.629 135.095 223.417 128.5 223.417 128.5Z"
			fill="#2C1B6F"
		/>
		<path
			d="M53.1027 155.908C53.1027 155.908 53.6895 163.22 58.5689 167.984C63.4491 172.746 70.7722 173.156 70.7722 173.156C70.7722 173.156 70.1853 165.844 65.3059 161.08C60.4272 156.318 53.1027 155.908 53.1027 155.908Z"
			fill="#33185C"
		/>
		<path
			d="M234.444 135.107C234.444 135.107 233.848 142.418 228.962 147.174C224.076 151.93 216.75 152.329 216.75 152.329C216.75 152.329 217.347 145.019 222.233 140.262C227.119 135.506 234.444 135.107 234.444 135.107Z"
			fill="#73D0C9"
		/>
		<path
			d="M64.2462 149.5C64.2462 149.5 70.3388 153.584 72.1103 160.169C73.8817 166.753 70.66 173.344 70.66 173.344C70.66 173.344 64.5673 169.26 62.7959 162.675C61.0245 156.091 64.2462 149.5 64.2462 149.5Z"
			fill="#8559C5"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1496_7922"
				x1="132.409"
				y1="92.6526"
				x2="140.129"
				y2="74.0981"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6437D9" />
				<stop offset="1" stopColor="#5E1BB1" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1496_7922"
				x1="107.14"
				y1="62.5435"
				x2="81.6901"
				y2="71.6721"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6437D9" />
				<stop offset="1" stopColor="#5E1BB1" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1496_7922"
				x1="93.6461"
				y1="71.3179"
				x2="82.6496"
				y2="73.0051"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6437D9" />
				<stop offset="1" stopColor="#5E1BB1" />
			</linearGradient>
			<clipPath id="clip0_1496_7922">
				<rect
					width="242.826"
					height="157.44"
					fill="white"
					transform="matrix(-1 0 0 1 292 16.75)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default AlreadyAppliedIllustration;
