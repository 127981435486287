import React from 'react';
import NotFoundIllustration from './NotFoundIllustration';

export const ErrorPage = ({ message }) => (
	<section className="pb-0 bg-white" style={{ minHeight: '75vh' }}>
		<div className="container">
			<div className="row">
				<div className="col-12 text-center pt-5">
					<h2>{message}</h2>
					<NotFoundIllustration />
				</div>
			</div>
		</div>
	</section>
);
